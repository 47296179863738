<template>
<span>
    <v-container fluid>

        <v-row class="mb-16">

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                <v-card color="light-green darken-1" dark height="150" outlined>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="titulo ">Vendas no mês {{mes}}/{{ano}}</v-card-title>

                            <v-card-subtitle class="subtitle">
                                <p class="font-weight-black subtitle mt-10">{{qde_pedidos_mes}} Pedido(s)<br>
                                R$ {{total_vendido_mes}}</p>
                            </v-card-subtitle>
                        </div>

                        <v-avatar class="ma-3" size="120" tile>
                            <v-img :src="`${base_url}upload/sales_a.png`"></v-img>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                <v-card color="cyan darken-1 darken-1" dark height="150" outlined>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="titulo ">Vendas no ano de {{ano}}</v-card-title>

                            <v-card-subtitle class="subtitle">
                                <p class="font-weight-black subtitle mt-10">{{qde_pedidos_ano}} Pedido(s)<br>
                                R$ {{total_vendido_ano}}</p>
                            </v-card-subtitle>
                        </div>

                        <v-avatar class="ma-3" size="120" tile>
                            <v-img :src="`${base_url}upload/sales_a.png`"></v-img>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="4">
                <v-card color="orange lighten-1" dark height="150" outlined>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="titulo">Clientes Cadastrados</v-card-title>

                            <v-card-subtitle>
                                <h1 class="font-weight-black subtitle mt-10">{{qde_usuarios}}</h1>
                            </v-card-subtitle>
                        </div>

                        <v-avatar class="ma-3" size="120" tile>
                            <v-img :src="`${base_url}upload/users_a.png`"></v-img>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>
            
        </v-row>

        <v-layout row wrap class="d-flex align-content-end">
            <v-flex xs12 sm12 md12 lg6 xl6 >
                <bar-chart :labels="labels" :datasets="datasets"></bar-chart>
            </v-flex>
            <v-flex xs12 sm12 md12 lg6 xl6>
                <bar-chart :labels="mais_vendidos.labels" :datasets="mais_vendidos.datasets"></bar-chart>
            </v-flex>
        </v-layout>
    </v-container>
</span>
</template>

<script>
import BarChart from '../../charts/lines/BarChart'
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        var today = new Date();
        this.ano = today.getFullYear();
        this.mes = today.getMonth() + 1;
    },
    mounted() {
        this.$store.dispatch('loadOrdersByMonth')
            .then(response => {
                this.datasets[0].data = response.datasets
                this.labels = response.labels
                this.qde_pedidos_mes = response.qde_pedidos_mes
                this.qde_pedidos_ano = response.qde_pedidos_ano
                this.qde_usuarios = response.qde_usuarios
                this.total_vendido_mes = response.total_vendido_mes
                this.total_vendido_ano = response.total_vendido_ano
                //this.bkgColor[0] = response.bkgColor
            })

        this.$store.dispatch('loadBestSellProduct')
            .then(response => {
                this.mais_vendidos.datasets[0].data = response.datasets
                this.mais_vendidos.labels = response.labels
            })
    },
    components: {
        BarChart
    },
    name: 'DashboardComponent',
    data() {
        return {
            mais_vendidos: {
                labels: [],
                datasets: [{
                    label: 'Produtos Mais Vendidos',
                    backgroundColor: '#26A69A',
                    data: []
                }],
            },
            labels: [],
            datasets: [{
                label: 'Vendas no Mês',
                backgroundColor: '#b0e218',
                data: []
            }],
            bkgColor: 1,
            qde_pedidos_mes: 0,
            qde_pedidos_ano: 0,
            qde_usuarios: 0,
            total_vendido_mes: 0,
            total_vendido_ano: 0,
            ano: '',
            mes: '',
            base_url: URL_BASE
        }
    },
    methods: {
        /*loadOrdersByMonth() {
            this.$store
                .dispatch('loadOrdersByMonth')
                .then(response => {
                    this.datasets[0].data = response.datasets
                    this.labels = response.labels
                    this.qde_pedidos_mes = response.qde_pedidos_mes
                    this.qde_pedidos_ano = response.qde_pedidos_ano
                    this.qde_usuarios = response.qde_usuarios
                    this.total_vendido_mes = response.total_vendido_mes
                })
                .catch(
                    this.$swal({
                        position: 'center',
                        type: 'error',
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    })
                )
        }*/

    }
}
</script>

<style scoped>
.dia {
    position: absolute;
    text-align: center;
    vertical-align: middle;
    padding-top: 32px;
    font-size: 1.5rem;
}

.subtitle {
    font-size: 2rem;
}

.titulo {
    font-size: 1.2rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
}
.subtitle {
    font-size: 1.5rem !important;
    margin: 0 !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
}
</style>
