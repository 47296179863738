import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data() {
    return {
      options: {
        legend: {
          labels: {
            fontColor: "#666",
            fontSize: 15
          }
        },
        title: {
          display: false,
          text: 'Chart.js Horizontal Bar Chart'
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                fontColor: '#666'
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                min: 0,
                fontColor: '#666'
              }
            }
          ]
        }
      }
    }
  },
  props: {
    labels: {
      require: true,
      type: Array
    },
    datasets: {
      require: true,
      type: Array
    }
  },
  mounted() {
    this.charts()
  },
  methods: {
    charts() {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets
      }, this.options)
    }
  },
  watch: {
    labels() {
      this.charts()
    }
  }
}
